import React from "react"

import * as C from "shared/components"

import { CopyHeading } from "../styled"
import ScrollMagicWrapper from "../ScrollMagic"

export const ExamplePersonas = ({ content }) => {
  return (
    <>
      <C.ContentWrapper>
        <div id="trigger" />
        <ScrollMagicWrapper triggerElement="#trigger">
          <CopyHeading maxWidth="34rem" color="dark">
            {content.Intro_Text}
          </CopyHeading>
        </ScrollMagicWrapper>
      </C.ContentWrapper>
    </>
  )
}
