import styled from "styled-components"

export const ScrollMagicHidden = styled.div`
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  height: inherit;
  width: 100%;
`
