import styled from "styled-components"
import { theme } from "shared/styles/theme"
import darkBg from "images/dark-bg.svg"

const { breakpoints, color } = theme

export const StyledDarkSection = styled.div`
  background: url(${darkBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${color.paleSalmon};
  padding-top: 3.125rem;
  margin-top: 10rem;
  display: flex;
  align-items: flex-start;
  z-index: 2;
  position: relative;

  @media (max-width: ${breakpoints.md}px) {
    display: block;
  }
`

export const ImageWrapper = styled.div`
  width: 50%;
  max-width: 60rem;
  margin-bottom: -10rem;
  position: relative;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    max-width: 100vw;
    margin-bottom: 0;
  }

  img {
    transform: translateX(-11%);
  }
`

export const DarkSectionWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5% 0 0 auto;
  align-items: flex-start;
  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    padding: 0 2rem;
    display: block;
  }
`

export const DarkSectionContent = styled.div`
  height: 23rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 5rem;
  padding-right: 2rem;

  & h2 {
    font-size: 2.625rem;
  }

  @media (max-width: ${breakpoints.md}px) {
    justify-content: flex-start;
    height: auto;
    padding-bottom: 5rem;
    padding-right: 0;

    & h2 {
      padding-bottom: 2rem;
      max-width: 100%;
      font-size: 1.5rem;
    }
  }
`
