import styled from "styled-components"

import heroBg from "images/hero-bg.svg"

import { theme } from "shared/styles/theme"

const { breakpoints } = theme
export const Hero = styled.div`
  width: 100%;
  padding-bottom: 25rem;
  background: url(${heroBg});
  background-size: contain;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  @media (max-width: ${breakpoints.md}px) {
    padding: 0 2rem 8rem;

    a {
      padding: 0 1rem;
    }
  }
`

export const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  margin-bottom: 3rem;
`

export const Claim = styled.h1`
  ${props => props.theme.typography.ttCommons.font660};
  color: ${props => props.theme.color.darkGrey};
  text-align: center;

  @media (max-width: ${breakpoints.md}px) {
    font-size: 2.1rem;
  }
`

export const Description = styled.p`
  ${props => props.theme.typography.ttCommons.font340};
  color: ${props => props.theme.color.darkGreyBlue};
  line-height: 1;
  text-align: center;
  margin: 1.125rem auto 0;
  max-width: 32rem;
  @media (max-width: ${breakpoints.md}px) {
    ${props => props.theme.typography.ttCommons.font240};
  }
`

export const ImageWrapper = styled.div`
  display: block;
  margin: 0 auto;
  margin-top: -22rem;
  width: 100%;
  max-width: 68rem;
  z-index: 1;
  position: relative;

  @media (max-width: ${breakpoints.md}px) {
    margin-top: -5rem;
  }
`

export const ButtonLabel = styled.span`
  display: inline-block;
  text-align: center;
`
