import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"

import * as L from "layouts"
import * as IC from "components/index"
import useAuth from "hooks/useAuth"

const Index = ({ data: { allStrapiLandingpage } }) => {
  const { isAuthenticated } = useAuth()
  const { node } = allStrapiLandingpage.edges[0]

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login")
    }
  }, [isAuthenticated])

  return (
    <L.IndexLayout>
      <IC.Root>
        <IC.Hero content={node} />
        <IC.ExamplePersonas content={node} />
        <IC.DarkSection content={node} />
        <IC.LightSection content={node} />
        <IC.PersonsSection content={node} />
        <div id="downloadSectionTrigger" />
        <IC.DownloadSection content={node} />
        <IC.Faq content={node} />
      </IC.Root>
    </L.IndexLayout>
  )
}

export const landingPageQuery = graphql`
  query {
    allStrapiLandingpage {
      edges {
        node {
          Dark_Section_Text
          Download_Section_Text
          Hero_CTA_Text
          Hero_Claim
          Hero_Description
          Pink_Section_Text
          FAQ {
            Question
            Answer
          }
        }
      }
    }
  }
`

export default Index
