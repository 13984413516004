import styled from "styled-components"
import { theme } from "shared/styles/theme"

const { breakpoints } = theme

export const StyledLightSection = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: -1;
  position: relative;
  padding-top: 15.8rem;
  padding-bottom: 10.3rem;

  & h2 {
    position: relative;
    z-index: 3;
    font-size: 2.625rem;
  }

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    padding: 5rem 0;

    & h2 {
      max-width: 100%;
      font-size: 1.5rem;
    }
  }
`

export const RightColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 60%;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    height: 50vw;
  }
`

export const ImageWrapper = styled.div`
  width: 37rem;
  max-width: 37rem;
  @media (max-width: ${breakpoints.md}px) {
    max-width: 100vw;
    width: 15rem;
    height: 15rem;
  }

  position: absolute;
  top: 110%;
  left: 50%;
  transform: translate(-59%, -38%);
  max-width: 70%;
`
export const SpiralImage = styled.img`
  width: 50rem;
  height: 50rem;
  display: block;
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
`
