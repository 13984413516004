import styled from "styled-components"
import { theme } from "shared/styles/theme"
import grayBg from "images/gray-bg.svg"

const { breakpoints } = theme

export const StyledDownloadSection = styled.div`
  background: url(${grayBg});
  background-position: 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding-top: 6.5rem;

  @media (max-width: ${breakpoints.md}px) {
    display: block;
  }
`

export const ImageWrapper = styled.div`
  width: 46.5rem;
  max-width: 46.5rem;
  padding-right: 5rem;

  @media (max-width: ${breakpoints.md}px) {
    padding: 0;
    width: 80%;
    max-width: 100vw;
    margin: 0 auto;
  }
`

export const DownloadSectionWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: -4.5rem 0 0 auto;
  align-items: flex-start;

  @media (max-width: ${breakpoints.md}px) {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
`

export const DownloadSectionContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding-right: 2rem;

  @media (max-width: ${breakpoints.md}px) {
    display: block;

    & h2 {
      max-width: 100%;
      text-align: center;
      padding: 3rem 2rem 5rem;
    }
  }
`
