import styled from "styled-components"
import { theme } from "shared/styles/theme"
import pinkBg from "images/pink-bg.svg"

const { breakpoints } = theme

export const StyledPersonsSection = styled.div`
  background: url(${pinkBg});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakpoints.md}px) {
    display: block;
  }
`

export const LeftSection = styled.div`
  position: relative;
  width: 56rem;
`

export const ImageWrapper = styled.div`
  width: 56rem;
  padding-right: 5rem;
  position: absolute;
  top: 5rem;
  right: 0;

  @media (max-width: ${breakpoints.md}px) {
    max-width: 100vw;
    padding: 6rem 0 0;
    position: static;
  }
`

export const PersonsSectionWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 0 auto;
  align-items: flex-start;
  padding: 22rem 2rem 7.5rem 0;

  & h2 {
    font-size: 2.625rem;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    padding: 3rem 1rem 5rem;

    & h2 {
      text-align: center;
      max-width: 100%;
      font-size: 1.5rem;
    }
  }
`

export const PersonsSectionContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`
