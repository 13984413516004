import styled from "styled-components"
import Img from "gatsby-image"

export const StyledTestimonials = styled.div`
  height: 37.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin: 0 auto;
`

export const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
`

export const Image = styled(Img)`
  border-radius: 50%;
  object-fit: cover;
`

export const Quote = styled.p`
  font-style: italic;
  text-align: center;
`

export const Name = styled.p`
  margin-top: 1.5rem;
  font-size: ${props => props.theme.font.size.lg};
  font-weight: bold;
  margin-bottom: 0;
`

export const Role = styled.p`
  font-size: ${props => props.theme.font.size.sm};
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 1rem;
`
