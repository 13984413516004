import styled from "styled-components"

import { theme } from "shared/styles/theme"

const {
  color: { white, darkGrey },
  breakpoints,
} = theme

export const Root = styled.div`
  .visible {
    opacity: 1;
    transform: translateY(0);
  }
`

export const CopyHeading = styled.h2`
  ${props => props.theme.typography.ttCommons.font560};
  line-width: 1;
  color: ${darkGrey};
  text-align: left;
  color: ${({ color }) => (color === "light" ? white : darkGrey)};
  max-width: ${({ maxWidth }) => maxWidth || "31.25rem"};

  @media (max-width: ${breakpoints.md}px) {
    font-size: 2rem;
  }
`
export const Crescent = styled.div`
  height: ${props => props.width * 2}px;
  width: ${props => props.width}px;
  background-color: ${props => props.color || theme.color.peach};
  position: absolute;
  top: ${props => props.top || "auto"};
  left: ${props => props.left || "auto"};
  right: ${props => props.right || "auto"};
  bottom: ${props => props.bottom || "auto"};
  border-top-${props => props.dir}-radius: ${props => props.width * 2}px;
  border-bottom-${props => props.dir}-radius: ${props => props.width * 2}px;
  transform: ${props => props.transform};

  @media (max-width: ${breakpoints.md}px) {
    height: ${props => props.width}px;
    width: ${props => props.width / 2}px;
  }
`
