import React from "react"
import Collapsible from "react-collapsible"
import * as C from "shared/components"
import * as S from "./styled"
import ScrollMagicWrapper from "../ScrollMagic"

export const Faq = ({ content: { FAQ: faq } }) => (
  <ScrollMagicWrapper triggerElement="#faqTrigger">
    <div id="faqTrigger" />
    <C.ContentWrapper>
      <S.FaqWrapper>
        <S.FaqTitle>FAQ</S.FaqTitle>
        {faq.map((item, index) => (
          <Collapsible
            trigger={item.Question}
            triggerTagName="h2"
            transitionTime={100}
            // eslint-disable-next-line
            key={index}
          >
            <S.Answer>{item.Answer}</S.Answer>
          </Collapsible>
        ))}
      </S.FaqWrapper>
    </C.ContentWrapper>
  </ScrollMagicWrapper>
)
