import React from "react"

import * as C from "shared/components"

import ScrollMagicWrapper from "../ScrollMagic"
import { CopyHeading } from "../styled"
import * as S from "./styled"

export const LightSection = ({ content }) => {
  const { Light_Section_Text: lightSectionText } = content

  return (
    <ScrollMagicWrapper triggerElement="#lightSectionTrigger">
      <C.ContentWrapper>
        <div id="lightSectionTrigger" />
        <S.StyledLightSection>
          <CopyHeading maxWidth="30.5rem">{lightSectionText}</CopyHeading>
        </S.StyledLightSection>
      </C.ContentWrapper>
    </ScrollMagicWrapper>
  )
}
