import styled from "styled-components"
import { theme } from "shared/styles/theme"

const { breakpoints } = theme

export const FaqTitle = styled.h2`
  ${props => props.theme.typography.ttCommons.font560}
`

export const FaqWrapper = styled.div`
  padding-top: 7.5rem;
  padding-bottom: 9.25rem;

  & .Collapsible__trigger {
    ${props => props.theme.typography.ttCommons.font460}
    display: block;
    cursor: pointer;
    border-bottom: 1px solid ${props => props.theme.color.paleGrey};
    padding-top: 3.125rem;
    padding-bottom: 1.375rem;
    position: relative;

    @media (max-width: ${breakpoints.md}px) {
      ${props => props.theme.typography.ttCommons.font360}
      padding-right: 1rem;
    }

    &:after {
      content: "";
      display: block;
      width: 9px;
      height: 9px;
      border-bottom: 3px solid ${props => props.theme.color.black};
      border-right: 3px solid ${props => props.theme.color.black};
      transform: rotate(45deg);
      position: absolute;
      right: 0;
      top: 3.9rem;
      transform-origin: center;
      transition: transform 0.1s;
    }

    &.is-open:after {
      transform: rotate(-135deg);
    }
  }
`
export const Answer = styled.p`
  padding-top: 1.375rem;
  ${props => props.theme.typography.ttCommons.font340}

  @media (max-width: ${breakpoints.md}px) {
    ${props => props.theme.typography.ttCommons.font240}
  }
`
