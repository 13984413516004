import React from "react"
import { Controller, Scene } from "react-scrollmagic"

import * as S from "./styled"

const ScrollMagicWrapper = ({
  children,
  triggerElement,
  classToggle = "visible",
}) => (
  <Controller>
    <Scene
      duration={0}
      classToggle={classToggle}
      triggerElement={triggerElement}
      reverse={false}
      triggerHook={0.8}
    >
      <S.ScrollMagicHidden>{children}</S.ScrollMagicHidden>
    </Scene>
  </Controller>
)

export default ScrollMagicWrapper
